import { ReactNode } from 'react';

// Format the date
const formatterOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

// the dateFormatter function can accept both Date objects and string representations of dates. If the input is a string, it will be parsed into a Date object before being formatted. This allows you to use the same function for formatting both date types.
export function dateFormatter(date: Date | string): string | null {
  try {
    let dateObj: Date;

    // If date is already a Date object, use it directly
    if (date instanceof Date) {
      dateObj = date;
    } else {
      // If date is a string, parse it into a Date object
      dateObj = new Date(date);
    }

    // Check if dateObj is a valid Date
    if (isNaN(dateObj.getTime())) {
      console.log('Invalid date:', date);
      return 'Invalid date';
    }

    return dateObj.toLocaleDateString('en-US', formatterOptions);
  } catch (error) {
    console.error('Error formatting date:', error);
    return null; // or return a default value/error message
  }
}
