'use client';

// Third Party Plugins
import React from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
// Types
import { HomeContentCardProps } from './types';
// Utilities
import { dateFormatter } from '@utils/dateFormatter';
// Styles
import {
  StyledHomeContentContainer,
  StyledFontAwesomeIcon,
  StyledPostCardContainer,
  StyledPostCardImg,
  StyledMetaContainer,
} from './styles';
// Custom Components
import HomeContentCardText from '@/components/cards/HomeContentCardText';
import DefaultThumbnailImage from '../../images/DefaultThumbnailImage';
import Tag from '../../tags/Tag';

const HomeContentCard: React.FC<HomeContentCardProps> = ({
  type,
  to,
  image,
  altText,
  date,
  tagText,
  title,
  description,
  isOnHomePage,
}) => {
  return (
    <StyledPostCardContainer href={to} $type={type}>
      {type !== 'tomsBlog' && (
        <>
          {image ? (
            <StyledPostCardImg
              src={image}
              alt={altText}
              width={200}
              height={134}
            />
          ) : (
            <DefaultThumbnailImage
              height={134}
              width={200}
              borderRadius="var(--radius-md)"
            />
          )}
        </>
      )}
      <StyledHomeContentContainer $isOnHomePage={isOnHomePage}>
        {tagText && <MetaContent tag={tagText} date={date} />}
        <HomeContentCardText
          type={type}
          heading={title}
          excerpt={description}
          charLimitHeading={100}
          charLimitExcerpt={54}
          isOnHomePage={!!isOnHomePage}
        />
      </StyledHomeContentContainer>
      <StyledFontAwesomeIcon icon={faAngleRight} />
    </StyledPostCardContainer>
  );
};

const MetaContent: React.FC<{ tag: string; date: string }> = ({
  tag,
  date,
}) => {
  return (
    <StyledMetaContainer>
      <Tag type="primary" text={tag} color="var(--c-yellow-1)" />
      <time dateTime={date}>{dateFormatter(date)}</time>
    </StyledMetaContainer>
  );
};

export default HomeContentCard;
